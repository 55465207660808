.card {
  & > a {
    display: block;
    text-decoration: none;
    color: inherit;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    overflow: hidden;

    @media screen and (max-width: $no-gap-breakpoint) {
      box-shadow: none;
    }
  }
}

.account {
  padding: 10px;
  position: relative;

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--brand-color--med);
  }
}

.account-gallery__container {
  display: flex;
  flex-wrap: wrap;

  .empty-column-indicator {
    margin: -4px -2px;
  }
}

.account-gallery__item {
  @apply rounded-lg p-1;
  border: 0;
  box-sizing: border-box;
  display: block;
  position: relative;
  overflow: hidden;

  a {
    background: var(--brand-color--faint);
  }

  video,
  img {
    @apply rounded-lg;
  }

  &__icons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .svg-icon {
      @apply h-6 w-6;
    }
  }
}

.account__moved-note {
  padding: 14px 10px;
  padding-bottom: 16px;
  background: var(--brand-color--faint);
  border-top: 1px solid var(--brand-color--med);
  border-bottom: 1px solid var(--brand-color--med);
}

.account__joined-at {
  @apply text-gray-400;
  padding-left: 3px;
  font-size: 14px;
  display: flex;
  white-space: nowrap;
  flex-shrink: 0;

  .svg-icon {
    padding-right: 3px;
  }
}
